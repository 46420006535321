<template lang="pug">
  main
    section.projects
      router-link(v-for="p in projects" :to="'/proyectos/' + p.id + '/'" tag="figure" :key="p.id")
        img(:src="p.image")
        figcaption
          i.icon-view
          h3 {{ p.name }}
      div.filters
        .container
          ul
            li: a(href="#" @click.prevent="deleteFilters()" ).selected Ver todos
            li: a(href="#" @click.prevent="setFilter('telecom')" id="telecom") Telecomunicaciones
            li: a(href="#" @click.prevent="setFilter('solar')" id="solar") Energia Solar
</template>

<script>
export default {
  data() {
    return {
      filter: 0
    }
  },
  metaInfo() {
    return {
      title: 'Proyectos',
      meta:[
        {vmid: 'dtn', name:'description', content: 'Descubre la transformación que hemos logrado en nuestros proyectos.'},
        {vmid: 'kw', name:'keywords', content: 'Heila, proyectos, telecomunicaciones, energía solar, infraestructura de red de datos, restructuración de cableado de red, Morelia'},
        {vmid: 'inm', itemprop:'name', content: 'Heila | Proyectos'},
        {vmid: 'idtn', itemprop:'description', content: 'Descubre la transformación que hemos logrado en nuestros proyectos.'},
        {vmid: 'ptt', property:'og:title', content: 'Heila | Proyectos'},
        {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/proyectos'},
        {vmid: 'pdtn', property:'og:description', content: 'Descubre la transformación que hemos logrado en nuestros proyectos.'},
        {vmid: 'tdtn', name:'twitter:description', content: 'Descubre la transformación que hemos logrado en nuestros proyectos.'},
        {vmid: 'twtt', name:'twitter:title', content: 'Heila | Proyectos'}
      ]
    }
  },
  mounted() {
    document.getElementById("side-locator").innerText = 'Proyectos'
    document.body.classList.remove("solar")
    document.body.classList.remove("telecom")
    document.body.classList.add("solar")
  },
  computed: {
    projects() {
      if(this.filter == 0) {
        return this.$store.getters.getProjects
      } else {
        return this.$store.getters.getProjectsByType(this.filter)
      }
    }
  },
  methods: {
    random (min, max)
    {
      return Math.floor(Math.random()*(max-min+1)+min)
    },
    deleteFilters() {
      this.filter = 0
      this.$el.querySelectorAll("a").forEach(e => {
        e.classList.remove("selected")
      })
      this.$el.querySelectorAll("a")[0].classList.add("selected")
    },
    setFilter(name) {
      this.filter = name
      this.$el.querySelectorAll("a").forEach(e => {
        e.classList.remove("selected")
        if(e.id == name)
          e.classList.add("selected")
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
figure
  //background white
  //img
    //filter grayscale(100%)
</style>
