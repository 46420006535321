<template lang="pug">
  main.page
    .container
      .flex
        section.inverted
          h1.line-top Contáctanos
          p Si deseas mejorar tus procesos en estas áreas, ponemos nuestros servicios a tu disposición para cualquier proyecto.
          figure
            img(src="@/assets/img/contact.jpg")
            figcaption
              ul
                li Horario de atención: de <b>9:00</b> a <b>18:00</b> horas, de Lunes a Viernes.

          h1.line-top Envíanos un<br>mensaje
         
          form.contacto(@submit.prevent="submit")
            div.flex
              div.column
                input(type="text" placeholder="Nombre" v-validate="'required'" name="nombre" v-model="nombre")
              div.column
                input(type="text" placeholder="Correo" v-validate="'required|email'" name="correo" v-model="correo")
            div.flex
              div.column
                input(type="text" placeholder="Telefono" v-validate="'required'" name="telefono" v-model="telefono")
              div.column
                select(v-model="area")
                  option(value="0") -- Áreas de interés --
                  option(value="Telecomunicaciones") Telecomunicaciones
                  option(value="Energia Solar") Energía Solar
            div.flex
              textarea(v-validate="'required'" name="mensaje" v-model="mensaje")
            div.flex
              button(type="submit" ref="btn") Enviar
        div.map
          GmapMap(:center="{lat:19.688942, lng:-101.1646997}" :options="mapOptions" :zoom="16" map-type-id="terrain" style="width: 100%; height: 100%")
            GmapMarker(key="ubicacion" :position="{lat:19.688942, lng:-101.1646997}" :clickable="true" :draggable="false")
</template>

<script>
import aes256 from 'nodejs-aes256'
import crypto from 'crypto'
import axios from 'axios'

let config = {
  host    : 'smtp.webfaction.com',
  port    : 587,
  from    : '"Heila" <mail.sender@neodiko.com>',
  to      : 'contacto@heila.com.mx',
  subject : 'Formulario de Contacto - Heila',
  message : '',
  auth    : {
    user : 'mail_sender',
    pass : '4506lv5ms'
  }
}

export default {
  metaInfo() {
    return {
      title: 'Contacto',
      meta:[
        {vmid: 'dtn', name:'description', content: '¡Contáctanos!, ponemos nuestros servicios a tu disposición.'},
        {vmid: 'kw', name:'keywords', content: 'Heila, Morelia, contáctanos, dirección, domicilio, ubicación'},
        {vmid: 'inm', itemprop:'name', content: 'Heila | Contacto'},
        {vmid: 'idtn', itemprop:'description', content: '¡Contáctanos!, ponemos nuestros servicios a tu disposición.'},
        {vmid: 'ptt', property:'og:title', content: 'Heila | Contacto'},
        {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/contacto'},
        {vmid: 'pdtn', property:'og:description', content: '¡Contáctanos!, ponemos nuestros servicios a tu disposición.'},
        {vmid: 'tdtn', name:'twitter:description', content: '¡Contáctanos!, ponemos nuestros servicios a tu disposición.'},
        {vmid: 'twtt', name:'twitter:title', content: 'Heila | Contacto'}
      ]
    }
  },
  data() {
    return {
      nombre: '',
      correo: '',
      telefono: '',
      area: '0',
      mensaje: '',
      mapOptions: {
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        zoomControl: false,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.business",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#c9c9c9"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          }
        ]
      }
    }
  },
  mounted() {
    document.getElementById("side-locator").innerText = 'Contacto'
    document.body.classList.remove("solar")
    document.body.classList.remove("telecom")
    document.body.classList.add("solar")
  },
  methods: {
    submit() {
      this.$validator.validateAll().then((result) => {
        console.log(this.errors)
        if (result) {
          this.send()
          return
        }
      }).catch(error => console.error(error))
    },
    encryptMessage(json) {
      let password        = crypto.randomBytes(Math.ceil(10 / 2)).toString('hex').slice(0, 50)
      let message         = JSON.stringify(json)
      let messageEncrypt  = aes256.encrypt(password, message)
      let passwordEncrypt = new Buffer(password).toString('base64')
      return messageEncrypt.concat('\n' , passwordEncrypt)
    },
    clear() {
      this.nombre = ''
      this.correo = ''
      this.telefono = ''
      this.area = '0'
      this.mensaje = ''
    },
    send() {
      this.$refs.btn.classList.add("disabled")
      let json = config
      json.message = `Nombre: ${this.nombre}<br/>Correo: ${this.correo}<br/>Telefono ${this.telefono}<br/>Area: ${this.area}<br/>Mensaje: ${this.mensaje}`
      json = this.encryptMessage(json)
      axios({
        method:'post',
        url:'http://api.neodiko.com/mail/sendMail',
        data: { code: json }
      }).then((response) => {
        this.$refs.btn.innerHTML = "Mensaje Enviado con éxito!"
        this.clear()
      }).catch((error) => {
        this.$refs.btn.innerHTML = "Se ha producido un error"
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
  main.page
    background white
    section
      figure
        figcaption
          ul
            li
              text-transform none
              font-size 12px
              opacity 0.9
              color white
</style>
