<template lang="pug">
  div#main-nav
    div.scroll-outer
      div.scroll-inner
        nav
          ul
            li: router-link(to="/" exact) Inicio
            li.has-children
              a(href="#" @click.prevent.stop="openSub($event)") Servicios
              ul
                li: router-link(to="/telecom") Telecomunicaciones
                li: router-link(to="/solar") Energia Solar
            li: router-link(to="/proyectos") Proyectos
            li: router-link(to="/nosotros") Nosotros
            li: router-link(to="/contacto") Contacto
    div.social
      span Síguenos: 
      ul
        li: a(href="https://www.facebook.com/HeilaIT" target="_blank").icon-facebook
        li: a(href="https://www.instagram.com/heilaingenieriatecnologia/" target="_blank").icon-instagram
</template>

<script>
export default {
  data() {
    return {
      open: false
    }
  },
  mounted() {
    document.body.addEventListener("click", e => {
      if(this.open) {
        document.getElementsByClassName("menu-toggle")[0].classList.toggle("bars")
        document.getElementsByClassName("menu-toggle")[0].classList.toggle("cross")
        this.open = false
        this.$el.classList.remove("open")
      }

    })
  },
  methods: {
    toggleMenu() {
      if(!this.open) {
        this.$el.classList.add("open")
        this.open = true
      } else {
        this.$el.classList.remove("open")
        this.open = false
      }
    },
    openSub(e) {
      e.target.nextSibling.classList.toggle("opened")
    }
  }
}
</script>

<style>

</style>
