import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

import Sections from './views/Sections.vue'
import Telecomunicaciones from './views/Telecomunicaciones.vue'
import Solares from './views/Solares.vue'
import Proyectos from './views/Proyectos.vue'
import Contacto from './views/Contacto.vue'
import Nosotros from './views/About.vue'

import ProyectoSingle from './views/ProyectoSingle.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Home
    },
    {
      path: '/sections',
      component: Sections
    },
    {
      path: '/telecom',
      component: Telecomunicaciones
    },
    {
      path: '/solar',
      component: Solares
    },
    {
      path: '/proyectos',
      component: Proyectos,
    },
    {
      path: '/proyectos/:id',
      props: true,
      component: ProyectoSingle
    },
    {
      path: '/nosotros',
      component: Nosotros
    },
    {
      path: '/contacto',
      component: Contacto
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  }
})
export default router