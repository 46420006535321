<template lang="pug">
  main.page
    .container
      .flex
        section.inverted
          h1.line-top Acerca de nosotros
          p Heila es una empresa dedicada a brindar soluciones profesionales para cubrir las necesidades eléctricas y de telecomunicaciones de tu empresa.  Contamos con la tecnología, el personal especializado, la calidad y el compromiso para ayudarte.
          p Sabemos que te importa cuidar tu planeta y al mismo tiempo ahorrar en tus gastos fijos.
          
          figure(style="margin-bottom: 30px")
            img(src="@/assets/img/about.jpg")
          h1 Nuestro Proceso
          div.process
            div.step
              div.image
                img(src="@/assets/img/analysis.svg")
              p Análisis de<br>necesidades
            div.arrow
              i.icon-arrow-right
            div.step
              div.image
                img(src="@/assets/img/design.svg")
              p Diseño de<br>solución
            div.arrow
              i.icon-arrow-right
            div.step
              div.image
                img(src="@/assets/img/implementation.svg")
              p Implementación y<br>Documentación
            div.arrow
              i.icon-arrow-right
            div.step
              div.image
                img(src="@/assets/img/monitoring.svg")
              p Seguimiento
          div.split.flex
            div.half
              h1 Misión
              p Nuestra Misión es brindar servicios para implementar proyectos de telecomunicaciones y energías renovables para eficientizar procesos productivos en la industria
            div.half
              h1 Visión
              p Queremos ser la empresa líder en el estado de Michoacán en la integración de tecnología en materia de redes de datos y energía solar a la industria
          div.flex
            h1 Certificados
          div.flex
            h3 Telecomunicaciones
          div.cert
            a(href="https://www.netacad.com/es/careers/certifications" target="_blank")
              img(src="/img/cert/cisco.png")
              p.small Cableado Estructurado y<br>Administración de Servicios
            a(href="https://academy.grandstream.com/" target="_blank")
              img(src="/img/cert/grandstream.png")
              p VoIP
            a(href="https://dahuasolutions.co/certificacion_da/" target="_blank")
              img(src="/img/cert/dahua.png")
              p Video vigilancia
            a(href="https://www.provision-isr.com/" target="_blank")
              img(src="/img/cert/provision.png")
              p Video vigilancia
            a(href="https://www.denwaip.com/es/index.html" target="_blank")
              img(src="/img/cert/denwa.png")
              p VoIP
          div.flex
            h3 Energía Solar
          div.cert
            a(href="https://www.ance.org.mx/?ID=1029" target="_blank" style="margin-right: 100px")
              img(src="/img/cert/ance.png")
              p.small Energía Solar Fotovoltaica<br>(Sistemas Autónomos)
            a(href="https://www.ance.org.mx/?ID=1029" target="_blank")
              img(src="/img/cert/ance.png")
              p.small Sistemas Fotovoltaicos<br>interconectados a la red
    VFooter


</template>

<script>
import VFooter from "@/components/Footer.vue"
export default {
  components: {
    VFooter
  },
  mounted() {
    document.getElementById("side-locator").innerText = 'Nosotros'
    document.body.classList.remove("solar")
    document.body.classList.remove("telecom")
    document.body.classList.add("solar")
  },
  metaInfo: {
    title: 'Nosotros',
    meta:[
      {vmid: 'dtn', name:'description', content: 'Brindamos soluciones profesionales para cubrir las necesidades eléctricas y de telecomunicaciones de tu empresa.'},
      {vmid: 'kw', name:'keywords', content: 'Heila, nosotros, misión de Heila, visión de Heila, certificados en telecomunicaciones, certificados en energía solar, Morelia'},
      {vmid: 'inm', itemprop:'name', content: 'Heila | Nosotros'},
      {vmid: 'idtn', itemprop:'description', content: 'Brindamos soluciones profesionales para cubrir las necesidades eléctricas y de telecomunicaciones de tu empresa.'},
      {vmid: 'ptt', property:'og:title', content: 'Heila | Nosotros'},
      {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/nosotros'},
      {vmid: 'pdtn', property:'og:description', content: 'Brindamos soluciones profesionales para cubrir las necesidades eléctricas y de telecomunicaciones de tu empresa.'},
      {vmid: 'tdtn', name:'twitter:description', content: 'Brindamos soluciones profesionales para cubrir las necesidades eléctricas y de telecomunicaciones de tu empresa.'},
      {vmid: 'twtt', name:'twitter:title', content: 'Heila | Nosotros'}
    ]
  }
}
</script>

<style lang="stylus" scoped>
  main
    background white
    flex-direction column
    section
      width 70%
      flex 70% 0 0
      @media only screen and (max-width: 1024px)
        width 100%
        flex 100% 0 0 
    div.split
      margin-top 50px
      justify-content space-between
      @media only screen and (max-width: 500px)
        flex-direction column
      div.half
        width 45%
        flex 45% 0 0
        @media only screen and (max-width: 500px)
          flex 100% 0 0
          width 100%

    div.cert
      display flex
      flex-wrap wrap
      //align-items center
      justify-content space-evenly
      a
        margin-right 50px
        margin-bottom 50px
        position relative
      img
        opacity 0.2
        transition opacity ease-out 300ms
        -webkit-backface-visibility hidden
        height 60px
        &:hover
          opacity 0.3
      p
        text-align center
        position absolute
        white-space nowrap
        color rgba(black, 0.6)
        bottom -40px
        left 50%
        transform translate(-50%, 0)
        -webkit-backface-visibility hidden
        &.small
          font-size 11px
      @media only screen and (max-width: 500px)
        width 100%
        a
          height auto
          width 60%
          text-align center
          margin 0
          margin-bottom 40px
          width auto
          img
            //display block
            max-width 100%
            height auto

</style>
