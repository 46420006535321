<template lang="pug">
  main.telecom
    section.heading(ref="heading")
      .container
        div.image-ph
          img(src="@/assets/img/telecom.jpg")
        div.content
          .corners-top-left
          .pattern
          h1.line-top <span>Telecom</span>unicaciones
          p Servicios integrales a tu alcance
          .corners-bottom-left
      div.navigate
        button.enter.blue.icon-arrow-down(@click="scrollTitle()")
        div
          .mouse.blue
          p Scroll
    section.content.inverted
      .container
        h1.line-top Servicios
        div.project(v-for="(service, i) in services")
          span.number 0{{i+1}}
          figure
            div.img-ph
              img(:src="service.image")
            figcaption
              ul.list
                li(v-for="benefit in service.benefits") {{ benefit }}
          div.description
            div.text
              h2.line-top {{ service.name }}
              p {{ service.description }}
            div.buttons
              router-link(to="/proyectos" tag="button").transparent.icon Ver Proyectos
                i.icon-arrow-right
              router-link(to="/contacto" tag="button").blue.icon Cotizar
                i.icon-arrow-right
    VFooter
        
</template>

<script>
import anime from 'animejs'
import VFooter from '@/components/Footer.vue'
export default {
  components: {
    VFooter
  },
  data() {
    return {
      services: []
    }
  },
  metaInfo() {
    return {
      title: 'Telecomunicaciones',
      meta:[
        {vmid: 'dtn', name:'description', content: 'Disponemos de la tecnología, la capacidad y profesionalismo en telecomunicaciones.'},
        {vmid: 'kw', name:'keywords', content: 'Heila, telecomunicaciones, telecom, servicios de telecomunicaciones, cableado estructurado, telefonía ip, video vigilancia, configuración de servicios de red, administración de servicios de red, centro de datos, site, infraestructura de red de datos, restructuración de cableado de red, Morelia'},
        {vmid: 'inm', itemprop:'name', content: 'Heila | Telecomunicaciones'},
        {vmid: 'idtn', itemprop:'description', content: 'Disponemos de la tecnología, la capacidad y profesionalismo en telecomunicaciones.'},
        {vmid: 'ptt', property:'og:title', content: 'Heila | Telecomunicaciones'},
        {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/telecom'},
        {vmid: 'pdtn', property:'og:description', content: 'Disponemos de la tecnología, la capacidad y profesionalismo en telecomunicaciones.'},
        {vmid: 'tdtn', name:'twitter:description', content: 'Disponemos de la tecnología, la capacidad y profesionalismo en telecomunicaciones.'},
        {vmid: 'twtt', name:'twitter:title', content: 'Heila | Telecomunicaciones'}
      ]
    }
  },
  mounted() {
    document.getElementById("side-locator").innerText = 'Telecom'
    document.body.classList.remove("solar")
    document.body.classList.remove("telecom")
    document.body.classList.add("telecom")
  },
  created() {
    this.services = this.$store.getters.getServices('telecom')
  },
  methods: {
    scrollTitle() {
      const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
      anime({
        targets: scrollElement,
        scrollTop: this.$refs.heading.clientHeight + 60,
        duration: 500,
        elasticity: 0,
        easing: 'easeInOutQuad'
      })
    }
  }
}
</script>
