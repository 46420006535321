<template lang="pug">
  main.project
    section.project-heading
      .container
        div.content
          .corners-top-left
          .pattern
          h2 {{ project.name }}
          .corners-bottom-left
          p.line-top {{ project.description }}
          div.rect
    div.slider
      slick(:options="slickOptions" ref="slick" @afterChange="change")
        div(v-for="slide in project.slides"): img(:src="slide")
      div.controls
        div.buttons
          button.icon-arrow-left.left(@click="prev")
          button.icon-arrow-right.right(@click="next")
        span {{ currentSlide }} / {{ project.slides.length }}
    div.flex.details
      .container
        h2 Detalles del proyecto
        div.contents
          div.text
            p(v-html="project.description_large")
            div.square
              ul
                li <span>Fecha:</span> {{ project.date }}
                li <span>Cliente:</span> {{ project.client }}
                li <span>Status:</span> {{ project.status }}
                li <span>Locacion:</span> {{ project.location }}
          div.right
            Tabs(:content="project.details")
        div.buttons-nav
          div.half: button(v-if="before" @click="loadBefore").left.icon.transparent
            div.text: span {{ before.name }}
            i.icon-arrow-left
          div.half: button(v-if="after" @click="loadAfter").right.icon.transparent
            div.text: span {{ after.name }}
            i.icon-arrow-right
    VFooter

    
</template>

<script>
import Slick from 'vue-slick'
import Tabs from '@/components/Tabs.vue'
import VFooter from "@/components/Footer.vue"
export default {
  components: { Slick, Tabs, VFooter },
  data() {
    return {
      project: {},
      slickOptions: {
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        centerMode: false,
        centerPadding: '10%',
        focusOnSelect: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      currentSlide: 1,
      before: null,
      after: null,
    }
  },
  metaInfo() {
    return {
      title: this.project.name,
      meta: this.project.meta
    }
  },
  mounted() {
    document.getElementById("side-locator").innerText = 'Proyectos'
    document.body.classList.remove("solar")
    document.body.classList.remove("telecom")
    document.body.classList.add("solar")
  },
  watch: {
    '$route' (to, from) {
      //const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement
      //scrollElement.scrollTop = 0
      //this.id = to.params.id
      //this.getProject()
      //this.$refs.slick.reSlick()
    }
  },
  props: ["id"],
  created() {
    this.getProject()
    console.log(this.project)
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    change(event, slick, currentSlide) {
      this.currentSlide = currentSlide + 1
    },
    getProject() {
      const id = parseInt(this.id)
      this.project = this.$store.getters.getProjectById(id)
      this.before = null
      this.after = null
      if(this.$store.getters.getProjectById(id - 1)) this.before = this.$store.getters.getProjectById(id - 1)
      if(this.$store.getters.getProjectById(id + 1)) this.after = this.$store.getters.getProjectById(id + 1)
      
    },
    loadAfter() {
      this.$el.classList.toggle("right")
      setTimeout(() => {
        this.$el.classList.toggle("right")
        const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
        scrollElement.scrollTop = 0
        this.$router.push("/proyectos/" + this.after.id)
      }, 500)
    },
    loadBefore() {
      this.$el.classList.toggle("left")
      setTimeout(() => {
        this.$el.classList.toggle("left")
        const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
        scrollElement.scrollTop = 0
        this.$router.push("/proyectos/" + this.before.id)
      }, 500)
    }
  }
}
</script>

<style lang="stylus" scoped>
  main.project
    transition all ease-out 500ms
    &.right
      transform translateX(30px)
      opacity 0
    &.left
      transform translateX(-30px)
      opacity 0
    p
      text-align justify

</style>
