<template lang="pug">
  section.full
    .container
      div.image-ph(ref="divImage")
        slot(name="image")
      div.content(ref="divContent")
        .corners-top-left
        .pattern
        h1.line-top(ref="h1Title")
          slot(name="title")
        p(ref="pText")
          slot(name="text")
        .corners-bottom-left
      div.navigate(ref="divNavigate")
        button.enter.orange.icon-arrow-right(ref="btnEnter" @click="exit")
        div 
          .mouse
          p Scroll para explorar
</template>

<script>
import anime from 'animejs'
export default {
  props: ["redirect"],
  mounted() {
    var scrolled = false
    this.$el.addEventListener("wheel", e => {
      if(!scrolled && e.deltaY > 1) {
        scrolled = true
        this.exit()
      }
    })
  },
  methods: {
    exit() {
      anime({
        targets: this.$refs.h1Title,
        translateY: '-50%',
        opacity: 0,
        duration: 800,
        elasticity: 0
      })
      anime({
        targets: this.$refs.pText,
        translateY: '-50%',
        opacity: 0,
        duration: 800,
        delay: 300,
        elasticity: 0
      })
      anime({
        targets: this.$refs.divContent,
        opacity: 0,
        duration: 1000,
        delay: 200,
        elasticity: 0
      })
      anime({
        targets: this.$refs.divImage,
        opacity: 0,
        delay: 600,
        duration: 1000,
        elasticity: 0,
        complete: (anim) => {
          this.$router.push(this.redirect)
        }
      })
      anime({
        targets: this.$refs.divNavigate,
        translateX: '-100%',
        opacity: 0,
        duration: 1000
      })
    }
  }
}
</script>

<style>

</style>
