<template lang="pug">
  main
    Heading(redirect="/sections")
      template(slot="title") Revolucionamos la Energía<br>y las Telecomunicaciones
        small Ingeniería y Tecnología
      template(slot="text") Somos la mejor opción en la implementación de energía solar y telecomunicaciones de tu empresa
      template(slot="image")
        img(src="@/assets/img/home.jpg")
        
</template>

<script>
import Heading from '@/components/Heading.vue'
import anime from 'animejs'
export default {
  components: {
    Heading
  },
  mounted() {
    document.getElementById("side-locator").innerText = 'Inicio'
    document.body.classList.remove("solar")
    document.body.classList.remove("telecom")
    document.body.classList.add("solar")
  },
  metaInfo: {
    title: 'Inicio',
    meta:[
      {vmid: 'dtn', name:'description', content: 'Somos la mejor opción en la implementación de energía solar y telecomunicaciones de tu empresa.'},
      {vmid: 'kw', name:'keywords', content: 'Heila, telecomunicaciones, telecom, servicios de telecomunicaciones, cableado estructurado, telefonía ip, video vigilancia, configuración de servicios de red, administración de servicios de red, centro de datos, site, energía solar, sistemas fotovoltaicos interconectados, paneles solares, sistemas fotovoltaicos aislados, calentadores solares, bombeo solar, infraestructura de red de datos, restructuración de cableado de red, Morelia'},
      {vmid: 'inm', itemprop:'name', content: 'Heila | Revolucionamos la Energía y las Telecomunicaciones'},
      {vmid: 'idtn', itemprop:'description', content: 'Somos la mejor opción en la implementación de energía solar y telecomunicaciones de tu empresa.'},
      {vmid: 'ptt', property:'og:title', content: 'Heila | Revolucionamos la Energía y las Telecomunicaciones'},
      {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/'},
      {vmid: 'pdtn', property:'og:description', content: 'Somos la mejor opción en la implementación de energía solar y telecomunicaciones de tu empresa.'},
      {vmid: 'tdtn', name:'twitter:description', content: 'Somos la mejor opción en la implementación de energía solar y telecomunicaciones de tu empresa.'},
      {vmid: 'twtt', name:'twitter:title', content: 'Heila | Revolucionamos la Energía y las Telecomunicaciones'}
    ]
  },
}
</script>
