export default [{
    type: "solar",
    image: '/img/services/fotovoltaicos-interconectados.jpg',
    name: 'Sistemas Fotovoltaicos Interconectados (SFVI)',
    description: 'Ofrecemos mediante un SFVI, que puedas aprovechar y utilizar la energía solar para la producción de energía eléctrica que consume tu negocio, misma que se inyecta a la red de la Comisión Federal de Electricidad (CFE) para que se te descuente respecto de la que consumes, todo ello a través de un medidor bidireccional que contabiliza lo que usas contra lo que generas; esto genera una inversión con tasa de retorno de 3.8 años en promedio y 25 años de vida útil son contaminar.',
    benefits: [
      "Una excelente inversión.",
      "Amigable con el Medio.",
      "Reduce CO2 a la atmósfera.",
      "Sin costos de mantenimiento."
    ]
  },
  {
    type: 'solar',
    image: '/img/services/fotovoltaicos-aislados.jpg',
    name: 'Sistemas Fotovoltaicos Aislados (SFVA)',
    description: 'Podemos integrar este tipo de sistemas que permiten aprovechar al máximo la energía solar para la producción de energía eléctrica, principalmente en zonas donde el acceso a esta energía es restringido ya que CFE no tiene cobertura en ese tipo de lugares y su implementación es costosa. Los SFVA a diferencia de los SFVI cuentan con un banco de baterías que almacenan la energía y a partir de ahí se distribuye bajo demanda del cliente; esta energia proporciona autonomía incluso en las noches y dias nublados.',
    benefits: [
      "Cuenta con un banco de baterias.",
      "Puede crecer a demanda.",
      "Cabañas, Huertas y Vivienda son los principales usos.",
      "Amigable con el medio ambiente."
    ]
  },
  {
    type: 'solar',
    image: '/img/services/calentadores-solares.jpg',
    name: 'Calentadores Solares',
    description: 'Los calentadores solares de tipo industriales, generan un ahorro económico y contribuyen con el uso de energías limpias, utilizando la energía solar que llega a la superficie en forma de radiación, disminuyendo así este tipo de consumo energético de un 50% hasta 75%.',
    benefits: [
      "Es una fuente de energía gratuita e inagotable",
      "Disposición de una mayor independencia energética con respecto a las compañías de gas con los incrementos de precios",
      "La inversión puede ser recuperada de 1 a 3 años"
    ]
  },
  {
    type: 'solar',
    image: '/img/services/bombeo-solar.jpg',
    name: 'Bombeo Solar',
    description: 'Esta aplicación es una excelente solución para abastecer sistemas de riego agroindustrial, agua potable y, en general, para el bombeo de agua. Es una solución ecológica, económica y viable, ayuda a reducir el consumo de energía eléctrica o de combustibles fósiles como diesel o gasolina para el funcionamiento de bombas de riego o de abastecimiento.',
    benefits: [
      "Son muy rentables, el costo-beneficio es alto",
      "No contamina el suelo ni el medio.",
      "Trabaja con energía solar.",
      "Excelente solución en las huertas sin energía."
    ]
  },
  {
    type: 'telecom',
    image: '/img/services/cableado-estructurado.jpg',
    name: 'Cableado estructurado',
    description: 'Ofrecemos el diseño e instalación de cableado estructurado, este es una infraestructura que forma parte del valor de sus instalaciones a través de un sistema completo de cableado en fibra y/o cobre así como dispositivos de comunicaciones asociados entre si que proporcionan confiabilidad en la comunicación de la empresa hacia dentro y fuera de la misma en la transmisión de voz, video y datos',
    benefits: [
      "Disponibilidad de la información.",
      "Mejor Administración y control.",
      "Escalabilidad ordenada.",
      "Velocidad y Seguridad."
    ]
  },
  {
    type: 'telecom',
    image: '/img/services/telefonia-ip.jpg',
    name: 'Telefonía IP',
    description: 'En la implementación de la Telefonía IP, contamos con tecnología basada en la voz sobre protocolo de Internet(VoIP), encargada de transformar la voz en paquetes de datos para que se puedan enviar a través de una red de cableado estructurado ya existente y/o vía  Internet, una excelente opción para potencializar tus servicios de red reduciendo costos en tu empresa.',
    benefits: [
      "Integración con el Sistema Informático",
      "Ahorro de costos",
      "Eficiencia en la localización de personas",
      "Aprovechamiento del equipamiento existente"
    ]
  },
  {
    type: 'telecom',
    image: '/img/services/videovigilancia.jpg',
    name: 'Video vigilancia',
    description: 'Brindamos la integración de uno de los servicios que corren sobre su red de datos a través de paquetes de video con tecnología de punta, compuesta por un grabador digital (DVR o NVR), un disco duro para guardar las grabaciones y las cámaras necesarias para vigilar y monitorear sus intereses. El grabador se conecta al cableado estructurado, lo que permite ver la imagen de las cámaras desde diversos dispositivos fijos o móviles, en cualquier lugar con acceso a la red o a través de internet.',
    benefits: [
      "Incremento en la tranquilidad",
      "Prevención y resolución de crímenes",
      "Monitoreo Remoto",
      "Reducir costos de vigilancia"
    ]
  },
  {
    type: 'telecom',
    image: '/img/services/administracion.jpg',
    name: 'Configuración y administración de servicios de Red',
    description: 'La integración de servicios de voz video y datos en una red de datos lleva consigo inversiones y valores de la empresa en su quehacer diario, la correcta administración y control de estos activos es imperante; por lo que los servicios de configuración y administración son el punto de mayor desarrollo en una red de datos organizada; esto permite a la empresa asignar prioridades, restricciones, derechos, velocidad y registro de toda la actividad de los usuarios trabajadores en la red de la empresa.',
    benefits: [
      "Aprovechar mejor los recursos.",
      "Seguridad en la información y transacciones.",
      "Administración y control sobre usuarios.",
      "Corrección de fallas en los procesos."
    ]
  },
  {
    type: 'telecom',
    image: '/img/services/site.jpg',
    name: 'Centros de Datos SITE',
    description: 'Contamos con los elementos necesarios que tu empresa necesita para la construcción e implementación de un Centro de Datos SITE, (Centros de Cableado Estructurado, Switches, Routers, Nvrs, pbx, entre otros) indispensable para la concentración, el procesamiento y protección de la información de tu organización.',
    benefits: [
      "Mayor eficiencia, menor costo",
      "Crecimiento del negocio",
      "Disponibilidad de la informacion",
      "Centro de Datos personalizado"
    ]
  }
]