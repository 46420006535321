<template lang="pug">
  main.solar
    section.heading(ref="heading")
      .container
        div.image-ph
          img(src="@/assets/img/solar.jpg")
        div.content
          .corners-top-left
          .pattern
          h1.line-top Energía<span>Solar</span>
          p Aprovecha al máximo la energía solar y ahorra en gastos por consumo eléctrico.
          .corners-bottom-left
      div.navigate
        button.enter.orange.icon-arrow-down(@click="scrollTitle()")
        div
          .mouse 
          p Scroll
    section.content.inverted
      .container
        h1.line-top Servicios
        div.project(v-for="(service, i) in services")
          span.number 0{{i+1}}
          figure
            div.img-ph
              img(:src="service.image")
            figcaption
              ul.list
                li(v-for="benefit in service.benefits") {{ benefit }}
          div.description
            div.text
              h2.line-top {{ service.name }}
              p {{ service.description }}
            div.buttons
              router-link(to="/proyectos" tag="button").transparent.icon Ver Proyectos
                i.icon-arrow-right
              router-link(to="/contacto" tag="button").orange.icon Cotizar
                i.icon-arrow-right
    VFooter
        
</template>

<script>
import anime from 'animejs'
import VFooter from '@/components/Footer.vue'
export default {
  components: {
    VFooter
  },
  data() {
    return {
      services: []
    }
  },
  mounted() {
    document.getElementById("side-locator").innerText = 'Solares'
    document.body.classList.remove("solar")
    document.body.classList.remove("telecom")
    document.body.classList.add("solar")
  },
  metaInfo() {
    return {
      title: 'Energía Solar',
      meta:[
        {vmid: 'dtn', name:'description', content: 'Aprovecha al máximo la energía solar y ahorra en gastos por consumo eléctrico.'},
        {vmid: 'kw', name:'keywords', content: 'Heila, energía solar, sistemas fotovoltaicos interconectados, paneles solares, sistemas fotovoltaicos aislados, calentadores solares, bombeo solar, Morelia'},
        {vmid: 'inm', itemprop:'name', content: 'Heila | Energía Solar'},
        {vmid: 'idtn', itemprop:'description', content: 'Aprovecha al máximo la energía solar y ahorra en gastos por consumo eléctrico.'},
        {vmid: 'ptt', property:'og:title', content: 'Heila | Energía Solar'},
        {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/solar'},
        {vmid: 'pdtn', property:'og:description', content: 'Aprovecha al máximo la energía solar y ahorra en gastos por consumo eléctrico.'},
        {vmid: 'tdtn', name:'twitter:description', content: 'Aprovecha al máximo la energía solar y ahorra en gastos por consumo eléctrico.'},
        {vmid: 'twtt', name:'twitter:title', content: 'Heila | Energía Solar'}
      ]
    }
  },
  created() {
    this.services = this.$store.getters.getServices('solar')
  },
  methods: {
    scrollTitle() {
      const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
      anime({
        targets: scrollElement,
        scrollTop: this.$refs.heading.clientHeight + 60,
        duration: 500,
        elasticity: 0,
        easing: 'easeInOutQuad'
      })
    }
  }
}
</script>
