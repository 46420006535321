import Vue from 'vue'
import Vuex from 'vuex'

import Projects from './projects'
import Services from './services'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    projects: Projects,
    services: Services,
    section: 'solar'
  },
  mutations: {

  },
  getters: {
    getProjects: state => {
      return state.projects
    },
    getProjectsByType: state => type => {
      return state.projects.filter(project => project.type == type)
    },
    getProjectById: state => id => {
      return state.projects.find(project => project.id == id)
    },
    getServices: state => type => {
      return state.services.filter(service => service.type == type)
    },
    getSection: state => {
      return state.section
    }
  },
  actions: {
  }
})
