<template lang="pug">
  #app
    Navigation(ref="navigation")
    header#main-header
      div.logo-ph
        router-link(to="/")
          img(src="./assets/img/logo.svg")
      ul.contact
        li
          h6 Llámanos
          span (443)1003637
        router-link(tag="li" to="/contacto")
          h6 Escríbenos
          span contacto@heila.com.mx
      button.menu-toggle(@click.stop="toggleMenu" ref="menuBtn").bars
        svg(width="30px" height="17px")
          line(x1="0" y1="1" x2="30" y2="1")
          line(x1="0" y1="8" x2="30" y2="8")
          line(x1="0" y1="15" x2="30" y2="15")
        span Menu
    aside#main-sidebar
      ul.sections
        div.navigator
        li: a(href="#" @click.prevent="" id="side-locator") {{ curTitle }}
      ul.social
        li: a(href="https://m.me/1077262012414180" target="blank" ).icon-messenger
        li: a(href="https://www.facebook.com/HeilaIT" target="_blank").icon-facebook
        li: a(href="https://www.instagram.com/heilaingenieriatecnologia/" target="_blank").icon-instagram
    transition(name="fade"): router-view(:key="$route.params.id")
</template>

<style lang="stylus" src="./assets/styles/index.styl"></style>

<script>
import Navigation from '@/components/Navigation.vue'
export default {
  components: {
    Navigation
  },
  data() {
    return {
      curTitle: ''
    }
  },
  metaInfo() {
    return {
      title: '',
      titleTemplate: 'Heila | %s',
      meta:[
        {vmid: 'dtn', name:'description', content: 'Somos la mejor opción en la implementación de energía solar y telecomunicaciones de tu empresa.'},
        {vmid: 'kw', name:'keywords', content: 'Heila, telecomunicaciones, telecom, servicios de telecomunicaciones, cableado estructurado, telefonía ip, video vigilancia, configuración de servicios de red, administración de servicios de red, centro de datos, site, energía solar, sistemas fotovoltaicos interconectados, paneles solares, sistemas fotovoltaicos aislados, calentadores solares, bombeo solar, infraestructura de red de datos, restructuración de cableado de red, Morelia'},
        {vmid: 'inm', itemprop:'name', content: 'Heila | Revolucionamos la Energía y las Telecomunicaciones'},
        {vmid: 'idtn', itemprop:'description', content: 'Somos la mejor opción en la implementación de energía solar y telecomunicaciones de tu empresa.'},
        {vmid: 'ptt', property:'og:title', content: 'Heila | Revolucionamos la Energía y las Telecomunicaciones'},
        {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/'},
        {vmid: 'pdtn', property:'og:description', content: 'Somos la mejor opción en la implementación de energía solar y telecomunicaciones de tu empresa.'},
        {vmid: 'tdtn', name:'twitter:description', content: 'Somos la mejor opción en la implementación de energía solar y telecomunicaciones de tu empresa.'},
        {vmid: 'twtt', name:'twitter:title', content: 'Heila | Revolucionamos la Energía y las Telecomunicaciones'},
        {itemprop:'image', content:'http://heila.com.mx/img/social.png'},
        {property:'fb:app_id', content:''},
        {property:'og:site_name', content:'Heila'},
        {property:'og:locale', content:'es_ES'},
        {property:'og:type', content:'website'},
        {property:'og:image', content:'http://heila.com.mx/img/social.png'},
        {name:'twitter:card', content:'summary_large_image'},
        {name:'twitter:site', content:'@IngenieriaHeila'},
        {name:'twitter:image', content:'http://heila.com.mx/img/social.png'}
      ]
    }
  },
  methods: {
    toggleMenu() {
      this.$refs.menuBtn.classList.toggle("cross")
      this.$refs.menuBtn.classList.toggle("bars")
      this.$refs.navigation.toggleMenu()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .fade-enter-active
  .fade-leave-active
    transition all ease-out .5s

  .fade-enter-active
    transition-delay .5s

  .fade-enter
  .fade-leave-to
    opacity 0
    transform translate(0, -20px)

</style>
