<template lang="pug">
  main.flex
    .container.flex
      section.telecom
        div.content
          figure(@click="openTelecom")
            div.img: img(src="@/assets/img/telecom.jpg")
            figcaption
              h1 Telecom<span class="color-blue">.</span>
              p Disponemos de la tecnología, la capacidad y profesionalismo en telecomunicaciones
              
      section.solar
        div.content
          figure(@click="openSolar")
            div.img: img(src="@/assets/img/solar.jpg")
            figcaption
              h1 Energía<br>Solar<span>.</span>
              p Colaboramos contigo para cambiar tu sistema eléctrico
    VFooter
</template>

<script>
import anime from 'animejs'
import VFooter from '@/components/Footer.vue'
export default {
  components: {
    VFooter
  },
  methods: {
    openTelecom() {
      this.$router.push('/telecom')
    },
    openSolar() {
      this.$router.push('/solar')
    }
  },
  mounted() {
    document.getElementById("side-locator").innerText = 'Servicios'
    document.body.classList.remove("solar")
    document.body.classList.remove("telecom")
    document.body.classList.add("solar")
  },
  metaInfo() {
    return {
      title: 'Servicios',
      meta:[
        {vmid: 'dtn', name:'description', content: 'Servicios en telecomunicaciones y energía solar.'},
        {vmid: 'kw', name:'keywords', content: 'Heila, telecomunicaciones, energía solar, Morelia'},
        {vmid: 'inm', itemprop:'name', content: 'Heila | Servicios'},
        {vmid: 'idtn', itemprop:'description', content: 'Servicios en telecomunicaciones y energía solar.'},
        {vmid: 'ptt', property:'og:title', content: 'Heila | Servicios'},
        {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/sections'},
        {vmid: 'pdtn', property:'og:description', content: 'Servicios en telecomunicaciones y energía solar.'},
        {vmid: 'tdtn', name:'twitter:description', content: 'Servicios en telecomunicaciones y energía solar.'},
        {vmid: 'twtt', name:'twitter:title', content: 'Heila | Servicios'}
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .container.flex
    height calc(100vh - 90px)
    flex-wrap wrap
  main
    flex-wrap wrap
  footer
    width 100%

</style>
