import Vue from 'vue'
import Meta from 'vue-meta'
import VeeValidate from 'vee-validate'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

Vue.use(Meta)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCN49QiI-YWMjURZxsZ5L_00QZb9NSswJw'
  }
})

Vue.use(VeeValidate)

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
