export default [
  {
    id: 1,
    image: '/img/projects/vw.jpg',
    name: 'Infraestructura de red de datos para Volkswagen',
    type: 'telecom',
    description: "Este proyecto tuvo como objetivo implementar y mejorar la infraestructura de las telecomunicaciones en diversos edificios de la empresa Volkswagen.",
    description_large: "En este proyecto, inicialmente se realizó la infraestructura de la red de datos necesaria para un nuevo edificio en la planta. Posteriormente, las necesidades se enfocaron en la reestructuración de cableado para el edificio principal de la empresa, debido a que ésta estaba en un estado de deterioro.<br>Actualmente se está realizando la instalación de tierra física en el Site, es decir este proyecto está en proceso.",
    date: 'Agosto 2018',
    client: 'Volkswagen, sucursal La Huerta',
    status: 'En proceso',
    location: 'Morelia, Michoacán',
    meta: [
      {vmid: 'dtn', name:'description', content: 'Infraestructura de red de datos y reestructuración de cableado de red.'},
      {vmid: 'kw', name:'keywords', content: 'Heila, telecomunicaciones, telecom, servicios de telecomunicaciones, cableado estructurado, infraestructura de red de datos, reestructuración de cableado de red, Volkswagen, Morelia'},
      {vmid: 'inm', itemprop:'name', content: 'Heila | Proyecto Volkswagen'},
      {vmid: 'idtn', itemprop:'description', content: 'Infraestructura de red de datos y reestructuración de cableado de red.'},
      {vmid: 'ptt', property:'og:title', content: 'Heila | Proyecto Volkswagen'},
      {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/1/'},
      {vmid: 'pdtn', property:'og:description', content: 'Infraestructura de red de datos y reestructuración de cableado de red.'},
      {vmid: 'tdtn', name:'twitter:description', content: 'Infraestructura de red de datos y reestructuración de cableado de red.'},
      {vmid: 'twtt', name:'twitter:title', content: 'Heila | Proyecto Volkswagen'}
    ],
    slides: [
      "/img/projects/vw1.jpg",
      "/img/projects/vw2.jpg",
      "/img/projects/vw3.jpg",
      "/img/projects/vw4.jpg",
      "/img/projects/vw5.jpg",
      "/img/projects/vw6.jpg",
    ],
    details: [
      {
        tab: "01. Detalles",
        contents: "<h4>Beneficios</h4><p>Con el nuevo cableado se aprovechará de mejor manera la velocidad de internet por la infraestructura y la categoría usada del cableado.</p><p>Se ha estandarizado la infraestructura y cableado dado a que cada equipo de cómputo cuenta con un nodo específico y patch cord a medida y de la misma categoría.</p>"
      }
    ]
  },
  {
    id: 2,
    image: "/img/projects/prefeco.jpg",
    name: 'Infraestructura de red de datos para PREFECO',
    type: 'telecom',
    description: "La preparatoria Melchor Ocampo, recientemente tuvo remodelaciones y construcción de nuevos edificios, por lo que su necesidad estaba centrada en la infraestructura de red de datos, además de optimizar procesos administrativos al interior.",
    description_large: "Para este proyecto, se implementó la telefonía VoIP para mejorar el medio de comunicación.<br>Además, se instalaron dos dispositivos para llevar el control de asistencias del personal de la institución.<br>Se instaló también un sistema de vídeo vigilancia, para mejorar la seguridad del plantel.",
    date: 'Actualmente (fijo)',
    client: 'PREFECO Melchor Ocampo',
    status: 'En proceso',
    location: 'Morelia, Michoacán',
    slides: [
      "/img/projects/prefeco1.jpg",
      "/img/projects/prefeco2.jpg",
      "/img/projects/prefeco3.jpg",
      "/img/projects/prefeco4.jpg",
      "/img/projects/prefeco5.jpg",
      "/img/projects/prefeco6.jpg",
    ],
    meta: [
      {vmid: 'dtn', name:'description', content: 'Infraestructura de red de datos, telefonía VoIP y vídeo vigilancia'},
      {vmid: 'kw', name:'keywords', content: 'Heila, telecomunicaciones, telecom, servicios de telecomunicaciones, cableado estructurado, infraestructura de red de datos, telefonía VoIP, vídeo vigilancia, PREFECO, preparatoria Melchor Ocampo, Morelia'},
      {vmid: 'inm', itemprop:'name', content: 'Heila | Proyecto PREFECO'},
      {vmid: 'idtn', itemprop:'description', content: 'Infraestructura de red de datos, telefonía VoIP y vídeo vigilancia'},
      {vmid: 'ptt', property:'og:title', content: 'Heila | Proyecto PREFECO'},
      {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/2/'},
      {vmid: 'pdtn', property:'og:description', content: 'Infraestructura de red de datos, telefonía VoIP y vídeo vigilancia'},
      {vmid: 'tdtn', name:'twitter:description', content: 'Infraestructura de red de datos, telefonía VoIP y vídeo vigilancia'},
      {vmid: 'twtt', name:'twitter:title', content: 'Heila | Proyecto PREFECO'}
    ],
    details: [
      {
        tab: "01. Detalles",
        contents: "<h4>Beneficios</h4><p>Con el nuevo cableado se aprovechará de mejor manera la velocidad de internet por la infraestructura y la categoría usada del cableado.</p><p>Se ha estandarizado la infraestructura y cableado dado a que cada equipo de cómputo cuenta con un nodo específico y patch cord a medida y de la misma categoría.</p><p>El contar con dos relojes checadores en diferentes áreas para el control de sus asistencias, ha optimizando y eficientado este proceso.</p><p>Todo el plantel está vigilado las 24:00 horas del día de manera física y remota gracias a al sistema de video vigilancia instalado.</p>"
      }
    ]
  },
  {
    id: 3,
    image: "/img/projects/chevrolet.jpg",
    name: 'Reestructuración de cableado de red para Chevrolet',
    type: 'telecom',
    description: "En este proyecto las necesidades se centralizaron en el área de las telecomunicaciones, principalmente estuvo dirigido a la reestructuración del cableado de la red y el Site.",
    description_large: "Durante este proyecto, la red en el edifico principal requería una reestructuración de cableado, debido a que su estructura actual estaba en un estado de deterioro. Por otro lado, la estructura del Site, o centro de datos, fue modificada para un mejor desempeño del sistema de cableado estructurado.",
    date: 'Julio 2018',
    client: 'Chevrolet Motores de Morelia',
    status: 'Terminado',
    location: 'Morelia, Michoacán',
    slides: [
      "/img/projects/chevrolet1.jpg",
      "/img/projects/chevrolet2.jpg",
      "/img/projects/chevrolet3.jpg",
      "/img/projects/chevrolet4.jpg",
      "/img/projects/chevrolet5.jpg",
      "/img/projects/chevrolet6.jpg",
    ],
    meta: [
      {vmid: 'dtn', name:'description', content: 'Reestructuración de cableado de red y modificación del centro de datos (SITE)'},
      {vmid: 'kw', name:'keywords', content: 'Heila, telecomunicaciones, telecom, servicios de telecomunicaciones, cableado estructurado, reestructuración de cableado de red, centro de datos, site, Chevrolet, Morelia'},
      {vmid: 'inm', itemprop:'name', content: 'Heila | Proyecto Chevrolet'},
      {vmid: 'idtn', itemprop:'description', content: 'Reestructuración de cableado de red y modificación del centro de datos (SITE)'},
      {vmid: 'ptt', property:'og:title', content: 'Heila | Proyecto Chevrolet'},
      {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/3/'},
      {vmid: 'pdtn', property:'og:description', content: 'Reestructuración de cableado de red y modificación del centro de datos (SITE)'},
      {vmid: 'tdtn', name:'twitter:description', content: 'Reestructuración de cableado de red y modificación del centro de datos (SITE)'},
      {vmid: 'twtt', name:'twitter:title', content: 'Heila | Proyecto Chevrolet'}
    ],
    details: [
      {
        tab: "01. Detalles",
        contents: "<h4>Beneficios</h4><p>Con el nuevo cableado se aprovechará de mejor manera la velocidad de internet por la infraestructura y la categoría usada del cableado.</p><p>Se ha estandarizado la infraestructura y cableado dado a que cada equipo de cómputo cuenta con un nodo específico y patch cord a medida y de la misma categoría.</p>"
      }
    ]
  },
  {
    id: 4,
    image: "/img/projects/marbran.jpg",
    name: 'Reestructuración de cableado de red para Mar Bran',
    type: 'telecom',
    description: "En este proyecto las necesidades fueron detectadas en el área de las telecomunicaciones, debido a la reestructuración del cableado de la red y la seguridad a través de la vídeo vigilancia.",
    description_large: "Para este proyecto se realizó, la reestructuración en el cableado de la red, en el edificio principal, debido a que solo se tenía acceso a internet por red inalámbrica, también se llevó a cabo la construcción de la red de datos, en un nuevo edificio de la empresa.<br>Además, se implementó un sistema de video vigilancia en el interior y áreas nuevas de la empresa en las que no se contaba con cámaras.",
    date: 'Septiembre 2018',
    client: 'Mar Bran',
    status: 'En proceso',
    location: 'Irapuato, Guanajuato',
    slides: [
      "/img/projects/marbran1.jpg",
      "/img/projects/marbran2.jpg",
      "/img/projects/marbran3.jpg",
      "/img/projects/marbran4.jpg",
      "/img/projects/marbran5.jpg",
      "/img/projects/marbran6.jpg",
    ],
    meta: [
      {vmid: 'dtn', name:'description', content: 'Reestructuración de cableado de red e implementación de sistema de video vigilancia'},
      {vmid: 'kw', name:'keywords', content: 'Heila, telecomunicaciones, telecom, servicios de telecomunicaciones, cableado estructurado, reestructuración de cableado de red, video vigilancia, Mar Bran, Morelia'},
      {vmid: 'inm', itemprop:'name', content: 'Heila | Proyecto Mar Bran'},
      {vmid: 'idtn', itemprop:'description', content: 'Reestructuración de cableado de red e implementación de sistema de video vigilancia'},
      {vmid: 'ptt', property:'og:title', content: 'Heila | Proyecto Mar Bran'},
      {vmid: 'purl', property:'og:url', content: 'http://heila.com.mx/4/'},
      {vmid: 'pdtn', property:'og:description', content: 'Reestructuración de cableado de red e implementación de sistema de video vigilancia'},
      {vmid: 'tdtn', name:'twitter:description', content: 'Reestructuración de cableado de red e implementación de sistema de video vigilancia'},
      {vmid: 'twtt', name:'twitter:title', content: 'Heila | Proyecto Mar Bran'}
    ],
    details: [
      {
        tab: "01. Detalles",
        contents: "<h4>Beneficios</h4><p>Con el nuevo cableado se aprovechará de mejor manera la velocidad de internet por la infraestructura y la categoría usada del cableado.</p><p>Se ha estandarizado la infraestructura y cableado dado a que cada equipo de cómputo cuenta con un nodo específico y patch cord a medida y de la misma categoría.</p><p>Mayor seguridad, debido a que tienen cubiertas todas las áreas de la empresa con Videovigilancia.</p>"
      }
    ]
  }
]