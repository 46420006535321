<template lang="pug">
  footer#main-footer
    .container
      .column
        div.logo-ph
          router-link(to="/")
            img(src="@/assets/img/logo-footer.svg")
        p.line-bottom Somos una empresa dedicada a proveer servicios en las telecomunicaciones y Energía Solar, respaldada por un equipo de profesionales especializados.
      
      .column
        h6 Menu
        ul
          li: router-link(to="/" exact) Inicio
          li: router-link(to="/sections") Servicios
          li: router-link(to="/proyectos") Proyectos
          li: router-link(to="/nosotros") Nosotros
          li: router-link(to="/contacto") Contacto
          li: a(href="http://heila.com.mx:2095/cpsess5868930050/webmail/paper_lantern/index.html?mailclient=horde" target="_blank") Correo
      .column
        h6 Contáctanos
        ul
          li Llámanos <span>(443)1003637</span>
          li Oficina <span>3148210</span>
          li Escríbenos <span>contacto@heila.com.mx</span>
    .pattern
    div.bottom
      .container
        .broken
          p Heila &copy; 2018
          p Desarrollado por 
            a(href="https://neodiko.com" target="_blanc") NEODIKO
          button.transparent-white.icon(@click="scrollTop()") Ir Arriba
            i.icon-arrow-up
        
</template>

<script>
import anime from 'animejs'

export default {
  methods: {
    scrollTop() {
      const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
      anime({
        targets: scrollElement,
        scrollTop: 0,
        duration: 500,
        elasticity: 0,
        easing: 'easeInOutQuad'
      })
      //window.scrollTo(0, 0)
    }
  }
}
</script>