<template lang="pug">
  div.tabs-container
    ul.tabs(ref="tabs")
      li(v-for="(t, i) in content"): a(href="#" @click.prevent="changeTab(i)") {{ t.tab }}
    div.contents(ref="content")
      div.tab(v-for="tab in content" v-html="tab.contents")
</template>

<script>
export default {
  data() {
    return {
      activeTab: 0
    }
  },
  props: ["content"],
  mounted() {
    this.$refs.tabs.children[this.activeTab].firstChild.classList.add("active")
    this.$refs.content.children[this.activeTab].classList.add("active")

    this.$refs.content.setAttribute("style", "height: " + this.$refs.content.children[this.activeTab].clientHeight + "px")
  },
  methods: {
    changeTab(tab) {
      this.$refs.tabs.children[this.activeTab].firstChild.classList.remove("active")
      this.$refs.tabs.children[tab].firstChild.classList.add("active")
      this.$refs.content.children[this.activeTab].classList.remove("active")
      this.$refs.content.children[tab].classList.add("active")
      this.activeTab = tab

      this.$refs.content.setAttribute("style", "height: " + this.$refs.content.children[this.activeTab].clientHeight + "px")
    }
  }

}
</script>

<style lang="stylus" scoped>
  div.tabs-container
    height 100%
    ul.tabs
      display flex
      justify-content space-between
      li
        flex-grow 1
        a
          text-transform uppercase
          color rgba(0,0,0,0.5)
          text-decoration none
          display block
          text-align left
          padding 0 10px 10px 10px
          letter-spacing 1px
          font-weight 600
          font-size 12px
          position relative
          &::after
            content ''
            background black
            width 100%
            height 1px
            bottom 0
            left 0
            position absolute
            opacity 0.5
          &.active
            &::after
              height 3px
              bottom -1px
    div.contents
      position relative
      div.tab
        opacity 0
        visibility hidden
        position absolute
        top 0
        left 0
        transition ease-out 300ms
        padding 0 10px
        &.active
          opacity 1
          visibility visible

</style>
